// 1) check tocken, add user to the store
import React, {useState, useEffect, useCallback} from "react";
import {hasToken, isTokenExpired} from "../services/utils";
import {getUser} from "../services/utils";
import {connect} from "react-redux";
import {saveUserInStore, clearUser} from "../store/actions/userActions";
import PUBLIC from "../config/publicRoute";
import ROLES from "../config/roles";
import {BrowserRouter as Redirect} from "react-router-dom";
import Loading from "../components/Navigation/Loading";
import PropTypes from "prop-types";

function withUserCheck(WrappedComponent) {
    UserCheckEnhancer.propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        user: PropTypes.object,
        render: PropTypes.bool,
        accessGrantedTo: PropTypes.array,
        userInStore: PropTypes.object,
        saveUserInStore: PropTypes.func,
        clearUser: PropTypes.func
    };

    function UserCheckEnhancer(props) {
        const {
            history,
            accessGrantedTo,
            match,
            userInStore,
            saveUserInStore,
            clearUser
        } = props;
        const [accessAllowed, setAccessAllowed] = useState(false);
        const [redirect, setRedirect] = useState(null);
        const [render, setRender] = useState(false);

        const checkAccess = useCallback(() => {
            // TODO : refacto the login to make it safer
            // check the role and if the token is set
            if (hasToken() && !isTokenExpired()) {
                return true;
            } else {
                return false
            }
        }, [accessGrantedTo, userInStore.type]);

        const handleRedirect = useCallback(() => {
            return history.push("/");
        }, [history, userInStore.type]);

        const isPublic = () => {
            return PUBLIC.indexOf(match.path) !== -1
        }

        useEffect(() => {
            const applyEffect = async () => {
                if (checkAccess() === true) {
                    if (match.path === "/") {
                        history.push('/orders')
                    }
                    setAccessAllowed(true);
                    setRender(true);
                } else {
                    handleRedirect();
                }
            };

            // Reminder: here goes the function executed everytime
            if (!hasToken() && !render) {
                if (!isPublic()) {
                    history.push("/");
                }
                setRender(true);
            } else {
                applyEffect();
            }
        }, [
            accessGrantedTo.length,
            handleRedirect,
            history,
            match.path,
            userInStore
        ]);

        if (render) {
            return (
                <WrappedComponent
                    {...props}
                    accessAllowed={accessAllowed}
                    redirect={redirect}
                    user={userInStore}
                    render={render}
                />
            );
        } else {
            return <Loading/>;
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(UserCheckEnhancer);
}

const mapStateToProps = state => {
    return {
        userInStore: state.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveUserInStore: user => dispatch(saveUserInStore(user)),
        clearUser: () => dispatch(clearUser())
    };
};

export default withUserCheck;
