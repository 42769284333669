const TXT = "Text";
const PDF = "PDF";
const PDF_PAGE = "PDF page";
const IMAGE = "Image";

export const FILES_TYPES = {
  TXT,
  PDF,
  PDF_PAGE,
  IMAGE
};
