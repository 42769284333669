const USER_CREATE = "Failed to create a user";
const USER_DELETE = "Failed to delete a user";
const USER_CHANGE = "Failed to update a user";
const LOGIN = "Name and/or password are not correct";
const FORGOT_PASS = "You did not create a user with thie email yet"
const LOGIN_PASSWORD = "passwords don't match"
const DATA_UPDATE = 'Failed to update the data'

export const ERROR = {
  USER_CREATE,
  USER_DELETE,
  USER_CHANGE,
  LOGIN,
  LOGIN_PASSWORD,
  FORGOT_PASS,
  DATA_UPDATE
};
