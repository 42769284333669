import { SAVE_USER_DATA, CLEAR_USER_DATA } from "../actions/userActions";

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_USER_DATA:
      return Object.assign({}, state, {
        ...action.userData
      });
    case CLEAR_USER_DATA:
      return (state = {});
    default:
      return state;
  }
};

export default userReducer;
