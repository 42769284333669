import { getToken, removeToken, setConfig } from "./localStorage";
import { FILES_TYPES } from "../config/filesTypes";
import jwt from "jsonwebtoken";

export function getUser() {
  if (getToken()) {
    return jwt.decode(getToken());
  } else {
    console.log("Could not get user");
    return null;
  }
}

export function hasToken() {
  let hasTokenCheck = false;
  if (getToken()) {
    hasTokenCheck = true;
  } else {
    hasTokenCheck = false;
  }
  return hasTokenCheck;
}

export function getFileType(type) {
  switch (type) {
    case "application/pdf":
      return FILES_TYPES.PDF;
    case "image/jpeg":
      return FILES_TYPES.IMAGE;
    case "image/png":
      return FILES_TYPES.IMAGE;
    case "txt":
      return FILES_TYPES.TXT;
    default:
      return null;
  }
}

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export function handleError(apiRes) {
  console.log("Error " + apiRes.status + " on " + apiRes.url);
  let message = "No response from API";
  if (apiRes) {
    switch (apiRes.status) {
      case "401":
        message = "Message 401";
        break;
      case "409":
        message = "Message 409";
        break;
      case "500":
        message = "Message 500";
        break;
      default:
        return message;
    }
  }
  return message;
}

export function isTokenExpired() {
  const token = getToken();
  const decode = jwt.decode(token);
  if (decode.exp < Date.now() / 1000) {
    return true;
  } else {
    return false;
  }
}

export function logout() {
  removeToken();
}
