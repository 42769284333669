import React from "react";
import "./App.scss";
import "./scss/global.scss";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import ROLES from "./config/roles";
import LoginPage from "./components/Pages/LoginPage";
import NotFound from "./components/Navigation/_NotFound";
import OrdersList from "./components/Pages/OrdersList";
import OrderDetails from "./components/Pages/OrderDetails";

function App() {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route
                        exact
                        path={"/orders"}
                        render={props => (
                            <OrdersList
                                {...props}
                                accessGrantedTo={[ROLES.ADMIN, ROLES.STAMPER]}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={"/order/:id"}
                        render={props => (
                            <OrderDetails
                                {...props}
                                accessGrantedTo={[ROLES.ADMIN, ROLES.STAMPER]}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/"
                        render={props => <LoginPage {...props} accessGrantedTo={[]}/>}
                    />
                    <Route path='/404' component={NotFound}/>
                    <Redirect from='*' to='/404'/>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
