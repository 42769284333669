import { createStore, applyMiddleware, compose } from 'redux'
//import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'

//const loggerMiddleware = createLogger()

const store = createStore(
    rootReducer, 
//	compose(
 //       applyMiddleware(
//		thunkMiddleware, // lets us dispatch() functions
//		loggerMiddleware // neat middleware that logs actions
//	),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//    )
)

export default store