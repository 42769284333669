import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import apiCall from "../../services/apiCall";
import Loading from "../Navigation/Loading";
import Informations from "../components/Informations";
import isHocsContainer from "../../hoc/isHocsContainer";

OrderDetails.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
    render: PropTypes.bool.isRequired,
    accessAllowed: PropTypes.bool.isRequired,
    redirect: PropTypes.string,
};

function OrderDetails(props) {
    const {match, history} = props;
    const [order, setOrder] = useState(false);
    const [render, setRender] = useState(false);

    async function fetchOrder(id) {
        try {
            let res = await apiCall.orders.get(id)
            if (res) {
                setOrder(res)
                setRender(true)
            } else {
                setRender(true)
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        // TODO : mettre le vrai id de l'aéroport (contenu dans le token)
        fetchOrder(match.params.id)
    }, []);
    if (render && order) {
        return (
            <>
                <Informations order={order}/>
            </>
        );
    } else if (render && !order) {
        return (
            <h1 className='titleTestPrivate'>Order doesn't exist</h1>)
    } else {
        return <Loading/>
    }

}

export default isHocsContainer(OrderDetails);
