import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function Loading() {
  return (
    // <div style={{ height: "100%", width: "100%", position: "fixed" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <CircularProgress />
      </div>
    // </div>
  );
}

export default Loading;
