export function setToken(token) {
  localStorage.setItem("token", token);
}


export function getToken() {
  return localStorage.getItem("token");
}


export function getConfigMode() {
  return localStorage.getItem("configMode");
}

export function setConfig(config, mode) {
  localStorage.setItem("configApi", config[mode].apiUrl);
  console.log("API is running on " + localStorage.getItem("configApi"));
  localStorage.setItem("configSocketIo", config[mode].socketIoUrl);
  console.log(
    "Socket Io is running on " + localStorage.getItem("configSocketIo")
  );
  localStorage.setItem("configMode", mode);
  console.log("Setting config to mode : " + mode);
}

export function removeToken() {
  localStorage.removeItem("token");
}
