import React from "react";
import { compose } from "redux";
import withUserCheck from "./withUserCheck";
import withLayout from "./withLayout";

const isHocsContainer = WrappedComponent => {
  const IsContainer = props => {
    return <WrappedComponent {...props} />;
  };

  return compose(withUserCheck, withLayout)(IsContainer);
};

export default isHocsContainer;
