import React, {useRef, useState} from "react";
import Loading from "../Navigation/Loading";
import isHocsContainer from "../../hoc/isHocsContainer";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import apiCall from "../../services/apiCall";
import {setToken} from "../../services/localStorage";
import {ERROR} from "../../config/errorsTypes";
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardContent} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content"
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120
    },
    formControlLabel: {
        marginTop: theme.spacing(1)
    },
    container: {
        backgroundColor: "rgb(30,30,30)",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        flexGrow: 1,
        paddingLeft: "11px"
    }
}));

function LoginPage(props) {
    const {render} = props;
    const classes = useStyles();
    const {history} = props;
    const [error, setError] = useState(null);
    const emailInput = useRef(null);
    const passInput = useRef(null);
    const validateInput = useRef(null);

    const logIn = async () => {
        const res = await apiCall.users.login(
            emailInput.current,
            passInput.current
        );
        if (res) {
            setToken(res.jwt);
            history.push("/orders")

        } else {
            loginSuccess(false, ERROR.LOGIN);
        }
    };
    const loginSuccess = () => {
        setError(true)
    };

    if (render) {
        return (
            <div className={classes.container}>
                <Card>
                    <CardContent>
                        <h1 className="titleTestPublic">Log in to see more!</h1>
                        <form
                            noValidate
                            onKeyDown={e =>
                                e.key === "Enter" ? validateInput.current.click() : null
                            }
                        >
                            <TextField
                                required
                                autoFocus
                                margin="dense"
                                id="email Login"
                                label="Name"
                                type="text"
                                ref={emailInput}
                                onChange={e => (emailInput.current = e.target.value)}
                                fullWidth
                            />
                            <TextField
                                required
                                margin="dense"
                                id="pass Login"
                                label="Password"
                                type="password"
                                ref={passInput}
                                onChange={e => (passInput.current = e.target.value)}
                                fullWidth
                            />
                            {error ? <p style={{color: "red"}}>Email or password incorrect</p> : null}
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                marginTop: 15
                            }}>
                                <Button
                                    ref={validateInput}
                                    onClick={() => logIn()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Log in
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
        );
    } else {
        return <Loading/>;
    }
}

export default isHocsContainer(LoginPage);
