import React, { useEffect, useState } from "react";
import { Button, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { saveAs } from "file-saver";
import apiCall from "../../services/apiCall";
import Snacks from "../Navigation/Snacks";

const useStyles = makeStyles({
  cell: {
    paddingLeft: 3,
    paddingRight: 3,
  },
  image: {
    maxWidth: 300,
    maxHeight: 300,
  },
});

function Informations(props) {
  const { user, history, order } = props;
  const styles = useStyles();
  const [process, setProcess] = useState(order.process);

  // Notification
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState("");

  const handleDownload = async () => {
    let cnt = 1;
    for await (let item of order.order_items) {
      if (item.url) {
        saveAs(item.url, `order${order.id}-item${cnt}.jpeg`);
      }
      cnt = cnt + 1;
    }
  };
  const handleSuccess = (bool, message) => {
    setMessage(message);
    setSuccess(bool);
    setOpenSnackBar(true);
  };
  const handleProcess = async (e) => {
    const res = await apiCall.orders.update(
      {
        process: e.target.value,
      },
      order.id
    );
    if (res) {
      setProcess(e.target.value);
      handleSuccess(true, "Status change !");
    } else {
      handleSuccess(false, "Something went wrong !");
    }
  };

  console.log(order);

  return (
    <>
      <Snacks
        success={success}
        message={message}
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <h1>Commande numéro {order.id}</h1>
      <p>
        Paiement :{" "}
        <span
          style={{
            backgroundColor: order.status === "completed" ? "green" : "red",
            color: "white",
            padding: 8,
            borderRadius: 33,
          }}
        >
          {order.status}
        </span>
      </p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>Etat de la commande (à modifier en interne) :</span>
        <Select
          variant={"filled"}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={process}
          onChange={(e) => handleProcess(e)}
        >
          <MenuItem value={"not_handled"}>A faire</MenuItem>
          <MenuItem value={"shipping"}>En livraison</MenuItem>
        </Select>
      </div>

      <h2>A envoyer à </h2>
      <p>{order.name}</p>
      <p>
        {order.address}, {order.city}, {order.country}, {order.postal_code}
      </p>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleDownload()}
      >
        Télécharger images
      </Button>
      <h2>Pains à préparer :</h2>
      {order.order_items.map((item, id) => (
        <div key={id} className={"bread-item"}>
          <h3>Numéro {id + 1}</h3>
          <p>Crumb : {item.crumb.name}</p>
          <p>Sourdough : {item.sourdough.name}</p>
          <p>Spice : {item.spice ? item.spice.name : "/"}</p>
          <p>
            Filling :{" "}
            {item.fillings && item.fillings.length > 0
              ? item.fillings.map((filling, idFilling) => (
                  <span id={idFilling}>{filling.name} </span>
                ))
              : "/"}
          </p>
          <p>
            Image :{" "}
            {item.url ? <img src={item.url} className={styles.image} /> : "/"}
          </p>
        </div>
      ))}
    </>
  );
}

export default Informations;
