import React, {useState} from "react";
import {makeStyles, createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import NavBar from "../components/Navigation/NavBar";
import PropTypes from "prop-types";
import {hasToken} from "../services/utils";
import {Container} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    content: {
        flexGrow: 1,
        width: "100vw",
        position: "relative"
    },
    escapeNav: {
        marginTop: 90
    }
}));


function withLayout(WrappedComponent) {
    LayoutEnhancer.propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        user: PropTypes.object,
        render: PropTypes.bool.isRequired
    };

    function LayoutEnhancer(props) {
        const classes = useStyles();
        const {match, history, user, render} = props;
        return (
            <>
                <div className={classes.root}>
                    {hasToken() ?
                        <NavBar
                            match={match}
                            history={history}
                            user={user}
                            render={render}
                        /> : null}
                    <main className={classes.content}>
                        {match.path !== '/' ? <><Container>
                            <div className={hasToken() ? classes.escapeNav : null}>
                                <WrappedComponent {...props} />
                            </div>
                        </Container>
                        </> : <WrappedComponent {...props} />}
                    </main>
                </div>
            </>
        );
    }

    return LayoutEnhancer;
}

export default withLayout;
