import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import apiCall from "../../services/apiCall";
import {
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
} from "@material-ui/core";
import { saveAs } from "file-saver";
import Button from "@material-ui/core/Button";
import Loading from "../Navigation/Loading";
import isHocsContainer from "../../hoc/isHocsContainer";
import Snacks from "../Navigation/Snacks";

const useStyles = makeStyles({
  cell: {
    paddingLeft: 3,
    paddingRight: 3,
  },
});
OrdersList.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  render: PropTypes.bool,
  accessAllowed: PropTypes.bool.isRequired,
  redirect: PropTypes.string,
};

function OrdersList(props) {
  const { user, history } = props;
  const [orders, setOrders] = useState([]);
  const [render, setRender] = useState(false);
  const [machineDelete, setMachineDelete] = useState(false);
  const [open, setOpen] = useState(false);

  // Notification
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  // Filter
  const [today, setToday] = useState(false);
  const [week, setWeek] = useState(false);
  const [toDo, setToDo] = useState(false);
  const [checked, setChecked] = useState([]);

  const classes = useStyles();

  const filter = async (label, type) => {
    let element = [];
    const todayQuery = `created_at_gt=${moment(new Date()).format(
      "YYYY-MM-DD"
    )}`;
    const weekQuery = `created_at_gt=${moment(new Date())
      .subtract(7, "days")
      .format("YYYY-MM-DD")}`;
    const toDoQuery = "process=not_handled";
    switch (label) {
      case "today":
        console.log("TODAY :" + todayQuery);
        element.push(todayQuery);
        setToday(true);
        setWeek(false);
        break;
      case "week":
        element.push(weekQuery);
        setToday(false);
        setWeek(true);
        break;
      case "not_handled":
        if (toDo) {
          setToDo(false);
        } else {
          element.push(toDoQuery);
          setToDo(true);
        }
        break;
    }
    if (today && type !== "date") {
      element.push(todayQuery);
    }
    if (week && type !== "date") {
      element.push(weekQuery);
    }
    if (toDo && type !== "status") {
      element.push(toDoQuery);
    }
    console.log(element);
    setOrders(await apiCall.orders.getAll(element.join("&")));
  };

  const handleSuccess = (bool, message) => {
    setMessage(message);
    setSuccess(bool);
    setOpenSnackBar(true);
  };
  const handleChange = async (id) => {
    let tmp_array = checked;
    tmp_array[id] = !checked[id];
    setChecked([...tmp_array]);
  };
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const handleDownload = async () => {
    let i = 0;
    for await (let check of checked) {
      let cnt = 0;
      if (check) {
        console.log(orders[i]);
        for await (let order of orders[i].order_items) {
          console.log(order.url);
          if (order.url) {
            await sleep(500);
            await saveAs(order.url, `order${orders[i].id}-item${cnt}.jpeg`);
          }
          cnt = cnt + 1;
        }
      }
      i = i + 1;
    }
    setChecked(new Array(orders.length).fill(false));
  };
  const fetchOrders = async () => {
    console.log("Fetching machine...");
    const res = await apiCall.orders.getAll();
    if (res) {
      setChecked(new Array(res.length).fill(false));
      setOrders(res);
    } else {
      setOrders([]);
    }
    setRender(true);
  };
  useEffect(() => {
    fetchOrders();
  }, []);
  if (render && orders) {
    return (
      <>
        <Snacks
          success={success}
          message={message}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
        />
        <h1 className="titleTestPrivate">Order list</h1>
        <Button
          onClick={() => filter("today", "date")}
          color="primary"
          variant={today ? "outlined" : "contained"}
        >
          Aujourd'hui
        </Button>
        <Button
          onClick={() => filter("week", "date")}
          color="primary"
          variant={week ? "outlined" : "contained"}
          style={{
            marginRight: 20,
          }}
        >
          Cette semaine
        </Button>
        <Button
          onClick={() => filter("not_handled", "status")}
          color="primary"
          variant={toDo ? "outlined" : "contained"}
        >
          A faire
        </Button>
        <Button
          onClick={() => handleDownload()}
          color="primary"
          variant={"outlined"}
          style={{ marginLeft: 20 }}
        >
          Download
        </Button>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <>
                <TableCell></TableCell>
                <TableCell className={classes.cell}>#</TableCell>
                <TableCell className={classes.cell}>Payment Status</TableCell>
                <TableCell className={classes.cell}>Status</TableCell>
                <TableCell className={classes.cell}>Created</TableCell>
                <TableCell className={classes.cell}>Quantity</TableCell>
                <TableCell className={classes.cell}>Infos</TableCell>
              </>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, index) => (
              <TableRow key={order.id}>
                <TableCell>
                  <Checkbox
                    color="default"
                    checked={checked[index]}
                    onChange={() => handleChange(index)}
                    inputProps={{ "aria-label": "checkbox with default color" }}
                  />
                </TableCell>
                <TableCell className={classes.cell}>{order.id}</TableCell>
                <TableCell className={classes.cell}>
                  {" "}
                  <span
                    style={{
                      backgroundColor:
                        order.status === "completed" ? "green" : "red",
                      color: "white",
                      padding: 8,
                      borderRadius: 33,
                    }}
                  >
                    {order.status}
                  </span>
                </TableCell>
                <TableCell className={classes.cell}>
                  {order.process === "not_handled" ? "A faire" : "En livraison"}
                </TableCell>
                <TableCell className={classes.cell}>
                  {moment(order.created_at).format("DD/MM")}
                </TableCell>
                <TableCell className={classes.cell}>
                  {order.order_items.length}
                </TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => history.push("order/" + order.id)}
                    variant="contained"
                    style={{
                      backgroundColor: "rgb(140,140,140)",
                      color: "white",
                    }}
                  >
                    Info
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  } else if (render) {
    return <h1 className="titleTestPrivate">No item</h1>;
  } else {
    return <Loading />;
  }
}

export default isHocsContainer(OrdersList);
