// exemples on GET, PUT, POST and DELETE
import {getToken} from "./localStorage";
import {hasToken} from "./utils";
const api = process.env.REACT_APP_API_URL || "http://localhost:1337";
//const api = "http://localhost:1337";

const apiCall = {
    users: {
        create: async (body) => {
            try {
                const res_create = await fetch(`${api}/auth/local/register`, {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        Authorization: "Bearer " + getToken(),
                        "Content-Type": "application/json",
                    },
                });
                if (res_create.ok) {
                    return true
                } else {
                    return false
                }
            } catch (err) {
                console.log(err);
            }
        },
        login: async (email, password) => {
            try {
                const body = {
                    identifier: email,
                    password: password,
                };
                const user = await fetch(`${api}/auth/local`, {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (user.ok) {
                    return await user.json();
                }
            } catch (err) {
                console.log(err);
            }
        },
        getAll: async (id) => {
            try {
                const machine = await fetch(`${api}/users`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                    },
                });
                if (machine.ok) {
                    return await machine.json();
                }
            } catch (err) {
                console.log(err);
            }
        },
    },
    orders: {
        get: async (id) => {
            try {
                const machine = await fetch(`${api}/orders/${id}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                    },
                });
                if (machine.ok) {
                    return await machine.json();
                }
            } catch (err) {
                console.log(err);
            }
        },
        getAll: async (query) => {

            try {
                const machine = await fetch(`${api}/orders${query ? "?" + query : ""}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                    },
                });
                if (machine.ok) {
                    return await machine.json();
                }
            } catch (err) {
                console.log(err);
            }
        },
        update: async (machine, id) => {
            try {
                let body = machine;
                console.log(body);
                const put = await fetch(`${api}/orders/${id}`, {
                    method: "PUT",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                    },
                });
                if (put.ok) {
                    return true;
                }
            } catch (err) {
                console.log(err);
            }
        },
        activate: async (id, token) => {
            try {
                let body = {}
                body.activate_token = token
                const put = await fetch(`${api}/machines/activate/${id}`, {
                    method: "PUT",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                    },
                });
                if (put.ok) {
                    return true;
                }
            } catch (err) {
                console.log(err);
            }
        },
        csv: async (form) => {
            try {
                const csv = await fetch(`${api}/machines/csv`, {
                    method: "POST",
                    body: form,
                    headers: {
                        Authorization: "Bearer " + getToken(),
                    },
                });
                if (csv.ok) {
                    return csv.json();
                }
            } catch (err) {
                console.log(err);
            }
        },
        delete: async (id) => {
            try {
                const res_delete = await fetch(`${api}/machines/${id}`, {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + getToken(),
                    },
                });
                if (res_delete.ok) {
                    return res_delete.json();
                }
            } catch (err) {
                console.log(err);
            }
        }
    },
};

export default apiCall;
