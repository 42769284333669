export const SAVE_USER_DATA = "SAVE_USER_DATA";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";

export const saveUserInStore = userData => {
  return {
    type: "SAVE_USER_DATA",
    userData
  };
};

export const clearUser = () => {
  return {
    type: "CLEAR_USER_DATA"
  };
};
